var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createUserGroup"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Group Name","label-for":"h-group-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"GroupName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-input',{attrs:{"id":"h-group-name","state":errors.length > 0 ? false:null,"placeholder":"Group Name"},model:{value:(_vm.group_name),callback:function ($$v) {_vm.group_name=$$v},expression:"group_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Chọn thư mục gốc","label-for":"h-choose-group","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Group"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-select',{attrs:{"id":"h-choose-group"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("Không chọn")]),_c('b-form-select-option',{attrs:{"value":"1"}},[_vm._v("Nhóm test")]),_c('b-form-select-option',{attrs:{"value":"2"}},[_vm._v("--- Nhóm test con")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tùy Chỉnh Icon","label-for":"h-icon","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Icon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-input',{attrs:{"id":"h-icon","placeholder":"Chọn icon đại diện"},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tùy chỉnh màu","label-for":"h-color","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Colour"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"sm":"6"}},[_c('color-picker',{attrs:{"value":_vm.colour,"picker":"chrome"},model:{value:(_vm.colour),callback:function ($$v) {_vm.colour=$$v},expression:"colour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Vị trí","label-for":"location","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-input',{attrs:{"id":"location","type":"number"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Đặt làm mặc định","label-for":"setdefault","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"SetDefault","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-checkbox',{attrs:{"id":"setdefault","checked":"true","switch":""},model:{value:(_vm.SetDefault),callback:function ($$v) {_vm.SetDefault=$$v},expression:"SetDefault"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-checkbox',{attrs:{"id":"status","switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }